.copilot {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  // padding: 0 10px 40px 10px;
  padding: 0 2px 30px 26px;

  &-datetime {
    display: flex;
    justify-content: end;
    margin-bottom: 11px;
    margin-right: 12px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;

    &-title {
      color: #002857;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      margin: 0;
      // margin-top: 30px;
    }
  }

  .showFilter {
    display: none;
  }
  
  &-content {
    display: flex;
    flex-direction: column;

    &-summaries {
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      align-items: center;
      gap: 27px;
      margin-bottom: 34px;
      // overflow: hidden;
      display: flex;

      .summaryCard {
        width: 25%;
      }
    }
    &-body {
      background-color: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 15px 20px;
      margin-top: 26px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &-title {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #002857;
          margin: 0;
        }
        &-tools {
          display: flex;
          gap: 10px;
          .ant-btn {
            color: #002857;
            font-size: 16px;
            font-weight: 500;
            height: 35px;
            width: 140px;
            border-radius: 5px;
            border: 1px solid #002857;
            font-family: Gotham;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:hover {
              svg {
                path {
                  fill: #4096ff;
                }
              }
            }
            &:disabled {
              svg {
                path {
                  fill: #002857;
                }
              }
            }
          }
          .ant-btn svg {
            margin-right: 5px;
          }
          .ant-btn-icon {
            // color: #002857;
          }
          &-calendar {
            border: 2px solid #bebebe;
            font-weight: 600;
            width: 250px;
          }
          &-rangerpicker {
            border: none;
            height: 35px;
            border-radius: 10px;

            @media (max-width: 576px) {
              border-radius: 6px;
            }

            input {
              color: #002857 !important;
              font-size: 14px !important;
              font-weight: 400 !important;

              @media (max-width: 576px) {
                font-weight: 400 !important;
              }
            }
          }
          &-footer {
            padding: 10px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // gap: 10px;

            button {
              margin-right: 10px;
            }
          }
          &-syncBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            box-sizing: border-box;
            width: 140px;
            height: 35px;
            border-radius: 5px;
            padding: 8px 30px;
            background: #0050bc;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
            cursor: pointer;

            .sync-icon {
              font-size: 22px;
              font-weight: 700;
            }
          }
        }
      }
      &-content {
        // max-height: 520px;
        // overflow-x: hidden;
        // overflow-y: auto;
        // margin-bottom: 20px;
        // padding: 20px 6px;

        table.check-in-table {
          width: 100%;
          box-sizing: border-box;
          padding: 0 14px;
          margin-bottom: 36px;

          thead {
            // height: 70px;
            font-weight: 700;
            text-align: left;
            color: #002857;

            tr {
              th {
                font-size: 14px;
                height: 36px;
                padding: 0 10px;
              }
            }
          }
          tbody {
            tr {
              // height: 63px;
              height: auto;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              text-transform: capitalize;
              color: #707070;

              td {
                height: 50px;
                min-height: 20px;
                padding: 0 10px;
              }

              .user-name {
                display: flex;
                align-items: center;
                gap: 12px;
                margin: 0;

                .user-icon {
                  box-sizing: border-box;
                  padding: 2px;
                  font-size: 16px;
                  background-color: #002857;
                  border-radius: 50%;
                  color: #fff;
                  border: none;
                }
              }

              .view-icon {
                color: #0063bc;
                font-size: 22px;
                cursor: pointer;
                &:hover {
                  color: #1380df;
                }
              }
            }
          }
        }
        .customerDataBox {
          display: none;
        }
      }
      &-bottom {
        a {
          display: flex;
          align-items: center;
          justify-content: end;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #02afef;
          text-decoration: none;
          text-transform: capitalize;

          svg {
            font-size: 20px;
            margin-left: 3px;
          }
        }
      }
    }
  }

  &.tablet {
    // padding: 10px 30px 40px 0px;
    padding: 0;

    .copilot-header {
      &-title {
        font-size: 18px;
      }
      .showFilter {
        display: inline-flex;
        margin-left: auto;
        align-items: center;
        height: 30px;
      }
      .btnFilter {
        border: none;
        padding: 0;
        height: auto;
        background: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
      margin-bottom: 20px;
    }
    .copilot-content {
      &-sliderBox {
        margin-bottom: 40px;
      }
      &-summaries {
        gap: 10px;
        margin-bottom: 30px;
      }
      .ant-carousel {
        .slick-track {
          gap: 44px;
          padding-bottom: 30px;
        }
        .slick-list {
          padding: 0 10px;
          width: calc(100% + 20px);
          margin-left: -10px;
          margin-right: -10px;
        }
        .slick-dots {
          bottom: 0;

          li {
            width: 8px;
            height: 8px;

            button {
              height: 100%;
              border-radius: 50%;
            }
          }
          li.slick-active {
            button {
              background: #002857 !important;
            }
          }
        }
      }
      &-body {
        background: none;
        padding: 0;
        &-header {
          margin-bottom: 20px;
          &-title {
            font-size: 18px;
          }
          &-tools {
            display: none;
            &-calendar {
              height: 30px;
            }
            &-syncBtn {
              display: none;
            }
          }
        }
        &-content {
          // display: grid;
          // grid-template-columns: repeat(1, 1fr);
          // gap: 25px;
          // margin-bottom: 25px;

          .customerDataBox {
            display: block;
            margin-bottom: 20px;
          }

          table {
            display: none;
          }
        }
        &-bottom {
          a {
            display: none;
          }
        }
      }
    }
  }

  &.mobile {
    // padding: 0 -12px 10px -12px;
    padding: 16px;
    .copilot-header {
      &-title {
        font-size: 16px;
      }
      .showFilter {
        display: inline-flex;
        margin-left: auto;
        align-items: center;
        height: 30px;
        
      }
      .btnFilter {
        border: none;
        padding: 0;
        height: auto;
        background: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
      }
      margin: 0 0 25px;
    }
    .copilot-content {
      &-sliderBox {
        margin-bottom: 30px;
        overflow: hidden;
      }
      .ant-carousel {
        .slick-track {
          gap: 30px;
          padding-bottom: 24px;
        }
        .slick-dots {
          bottom: 0;

          li {
            width: 8px;
            height: 8px;

            button {
              height: 100%;
              border-radius: 50%;
            }
          }
          li.slick-active {
            button {
              background: #002857 !important;
            }
          }
        }
      }
      &-body {
        background: none;
        padding: 0;
        margin-top: 22px;

        &-header {
          // margin-bottom: 25px;
          margin-bottom: 0;
          &-title {
            font-size: 16px;
          }
          &-tools {
            display: none;
            &-calendar {
              height: 30px;
              width: 180px;
              font-size: 12px;
              padding: 4px 8px;
            }
            &-syncBtn {
              display: none;
            }
          }
        }
        &-content {
          // display: grid;
          // grid-template-columns: repeat(1, 1fr);
          // gap: 16px;

          .customerDataBox {
            // display: flex;
            display: block;
            margin-bottom: 20px;
          }

          table {
            display: none;
          }
        }
        &-bottom {
          a {
            display: none;
          }
        }
      }
    }
  }
}

.slick-track {
  padding: 0px 0px 20px 0px;
  display: flex !important;
  gap: 18px;
}

.slick-dots li button {
  background-color: #909090 !important;
}

.ant-picker-time-panel {
  display: none !important;
}
@media (max-width: 768px) {
  .slick-track {
    gap: 7px; 
  }
}
.custom-calendar {
  @media (max-width: 576px) {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background: #2b2a2ae6;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    align-items: start;
    justify-content: center;
    display: flex;

    .ant-picker-range-wrapper {
      width: 95%;
      margin-top: 110px;
    }

    .ant-picker-datetime-panel {
      .ant-picker-date-panel {
        width: 100%;
      }
    }

    .ant-picker-date-panel .ant-picker-content {
      width: 100%;
    }

    .ant-picker-cell {
      min-width: 30px;

      &::before {
        height: 30px;
      }

      .ant-picker-cell-inner {
        height: 30px;
        line-height: 30px;
        min-width: 30px;
      }
    }
  }
}
