.segmentStatsCard {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 265px;
  padding-left: 12px;
  padding-top: 18px;

  .cardUpperSection {
    display: flex;
    margin-bottom: 20px;
   .iconset {
    padding-right: 22px;
    padding: 14px 22px 8px 0px;
   }
   .titleSet {
    padding: 5px 22px 8px 0px;
   }
    .percentageChip {
      align-self: center;
      margin-left: auto;
      height: 17px;
      padding: 5px;
    }
    .orangeBadge {
      background-color: #ffedd5 !important;
      color: #fea42e;
    }
    .redBadge {
      background-color: #fbe1e1 !important;
      color: #de2b2b;
    }
    .greenBadge {
      background-color: #e5f4eb !important;
      color: #16b21d;
    }
    .skeletonAvatar {
      margin-top: 14px !important;
    }
    .skeletonHeading {
      padding: 5px;
      width: 100px;
      border-radius: 15px;
    }
    .skeletonChip {
      width: 20px;
      border-radius: 10px;
    }
  }
  .cardLowerSection {
    display: flex;
        margin-bottom: 3px;
    svg {
      margin-left: auto;
      margin-top: 3px;
      width: 16px;
      height: 16px;
    }
    .skeletonText {
      width: 300px;
      border-radius: 10px;
    }
  }
}
@media only screen and (max-width: 1530px) and (min-width: 1000px) {
  .segmentStatsCard {
    .cardLowerSection {
      .skeletonText {
        width: 350px;
      }
    }
  }
}
@media only screen and (max-width: 850px) and (min-width: 550px) {
  .segmentStatsCard {
    .cardLowerSection {
      .skeletonText {
        width: 225px;
      }
    }
  }
}
@media only screen and (max-width: 380px) and (min-width: 320px) {
  .segmentStatsCard {
    .cardLowerSection {
      .skeletonText {
        width: 250px;
      }
    }
  }
}
