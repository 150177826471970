.forgotPassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 60px;
  .formTitle {
    color: #002857;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 70px;
  }
  .formHead {
    color: #002857;
    font-weight: 500;
    font-size: 18px;
  }
  .forgotPasswordButton {
    background-color: #0050bc;
    margin-top: 60px;
    margin-bottom: 10px;
    width: 450px;
    font-size: 18px;
    border-radius: 10px;
    padding: 10px;
  }
  .subText {
    color: #999999;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    .link {
      color: #0050bc;
      cursor: pointer;
    }
  }
  .ssoButtons {
    margin-top: 35px;
    gap: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    button {
      padding: 8px;
      border-radius: 10px;
      img {
        height: 45px;
      }
    }
  }
  .MuiOutlinedInput-root {
    height: 45px;
    color: #6f7174;
    font-weight: 500;
    border-radius: 10px;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
}

@media screen and (min-width: 1536px) {
  .forgotPassword {
    .forgotPasswordButton {
      width: 400px;
    }
  }
}

@media screen and (max-width: 1439px) and (min-width: 1025px) {
  .forgotPassword {
    .forgotPasswordButton {
      width: 350px;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 1025px) {
  .forgotPassword {
    margin-top: 40px;
    .formTitle {
      margin-bottom: 50px;
    }
    .forgotPasswordButton {
      margin-top: 40px;
      width: 300px;
    }
    .subText {
      margin-top: 10px;
    }
    .ssoButtons {
      gap: 20px;
      button {
        img {
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .forgotPassword {
    margin-top: 50px;
    .formTitle {
      margin-bottom: 85px;
    }
    .forgotPasswordButton {
      margin-top: 50px;
    }
    .ssoButtons {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 529px) {
  .forgotPassword {
    .forgotPasswordButton {
      width: 370px;
    }
  }
}
@media screen and (max-width: 400px) {
  .forgotPassword {
    .forgotPasswordButton {
      width: 300px;
    }
  }
}
