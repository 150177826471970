.api-keys-page {
  text-align: left;
  width: 100%;
  background: #fff;
  border-radius: 5px 5px 0 0;
  padding: 53px 30px;
  min-height: calc(100vh - 272px);
  box-sizing: border-box;

  h1.title {
    color: #002857;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: -19px 14px 45px;
  }

  h3.description {
    color: #707070;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
    margin-left: 18px;  
  }

  .api-keys-container {
    display: flex;
    justify-content: space-between;  
    align-items: flex-start;
    gap: 40px;
    margin-left: 18px;
  }

  .box {
    border-radius: 5px;
    border: 1px solid #C8C8CE;
    padding: 20px 15px;
    width: 50%;
    height: inherit;
    margin: 20px 0;
    gap: 20px;
    color: #707070;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;      

    .key-section {
      display: flex;
      justify-content: flex-start;	
      flex-direction: row;
      align-items: center;
      gap: 10px;  
    }

    .info {
      border-radius: 5px;
      background: rgba(150, 224, 255, 0.25);     
      color: #707070;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;	
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }

    .col-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }  

    .end {
      justify-content: flex-end;
    }

    .copy {
      cursor: pointer;
    }

    .docs-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .col-icon {
      display: flex;
      justify-content: center;
      flex-direction: column; 
    }

    .api-key {
      display: flex;
      border-radius: 5px;
      border: 1px solid #C8C8CE;
      padding: 7px 15px;

      .api-key-txt {
        width: 206px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;  
      }

      .tooltip-container {
        background: none;
        border: none;
        padding: 0;
      }
    }

    .rotate {
      cursor: pointer;
    }
  }
}

.tooltip-content {
  color: #707070;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: #707070 1px solid;
  border-radius: 5px;
  padding: 5px;
  background: #fff;
}

.tooltip-arrow {
  fill: #707070;
}

@media (max-width: 768px) {
  .api-keys-page {
    .api-keys-container {
      flex-direction: column;
      gap: 15px;

      .box {
        width: calc(100% - 30px);
        .key-section {
          .label {
            display: none;
          }
        }
        .api-key {
          width: 70%;
        }

        .hide-input {
          display: none;
        }
      }
    }
  }
}
