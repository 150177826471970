.showFilter {
  display: flex;
  align-items: flex-end;
 
  height: 60px;
}

.bold-100 {
  font-weight: 100 !important;
}
.bold-200 {
  font-weight: 200 !important;
}
.bold-300 {
  font-weight: 300 !important;
}
.bold-400 {
  font-weight: 400 !important;
}
.bold-500 {
  font-weight: 500 !important;
}
.bold-550 {
  font-weight: 550 !important;
}
.bold-600 {
  font-weight: 600 !important;
}
.bold-700 {
  font-weight: 700 !important;
}
.bold-800 {
  font-weight: 800 !important;
}
.bold-900 {
  font-weight: 900 !important;
}

.blue {
  color: #002857;
}

.light-gray {
  color: #C8C8CE;
}
.gray {
  color: #999999 !important;
}

.dark-gray {
  color: #6f7174;
}

.darker-gray {
  color: #4a4c4d;
}

.light-blue {
  color: #02afef;
}

.green {
  color: #16b21d;
}

.yellow {
  color: #fea42e;
}

.red {
  color: #de2b2b;
}

.custom-select .ant-select-selector {
  color: #002857 !important; 
  font-family: "Plus Jakarta Sans"!important;
  font-size: 16px;
}
.custom-select .ant-select-item-option {
  font-family: "Plus Jakarta Sans"!important;
  color: #002857 !important;
}

.custom-select .ant-select-dropdown {
  color: #002857 !important; 
}
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-select-item-option .ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: #002857 !important; 
}

.ant-select-item-option .ant-checkbox-checked .ant-checkbox-inner::after {
  display: block; 
  border-color: #002857 !important; 
}

.ant-select-item-option .ant-checkbox-checked + span {
  color: #002857 !important;
  font-weight: 400;
  font-family: "Plus Jakarta Sans" !important;
  font-size: 14px;
}
.ant-select-item-option:not(.ant-select-item-option-selected) .ant-checkbox + span {
  color: #999999 !important;
  font-family: "Plus Jakarta Sans"!important;
  font-size: 14px;
}

.custom-dropdown .ant-select-item-option-state {
  display: none; 
}

.custom-dropdown .ant-select-item-option-selected {
  background-color: transparent !important; 
}

.custom-checkbox .ant-checkbox-inner {
  border-radius: 0 !important;
}
.custom-input::placeholder {
 
  font-family: "Plus Jakarta Sans", sans-serif;
}
.ant-modal .ant-modal-header {
  margin-bottom: 4px;
}
