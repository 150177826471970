.settingsPage {
  text-align: left;
  width: 100%;
  background: #fff;
  border-radius: 5px 5px 0 0;
  // height: calc(235vh - 400px);
  box-sizing: border-box;
  // padding: 30px 30px;
  padding-top: 45px;
  // padding-left: 45px;
  margin-bottom: 15px;

  h1.title {
    color: #002857;

    // font-family: Gotham;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: -11px 9px 49px;
    // margin-left: 0px;
  }
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-card-bordered {
    border: 0px solid #f0f0f0; 
}
  // :where(.css-dev-only-do-not-override-1uq9j6g).ant-card .ant-card-body {
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   padding-bottom: 10px;
  //   padding-top: 10px;
  // }
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-card .ant-card-body {
    padding: 0; 
     border-radius: 0; 
}
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-card .ant-card-head {
    padding: 0px;  
    margin-left: 10px;
  
  }
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-card {
font-size: 14px;
font-family: "Gotham";
    margin-left: 10px;
  }
 

  

  .ant-tabs {
    .ant-tabs-nav {
      &::before {
        border: none;
      }

      .ant-tabs-nav-wrap {}

      .ant-tabs-ink-bar {
        background: #02afef;
        height: 4px;
        border-radius: 20px;
       

      }

      .ant-tabs-tab {
        padding: 6px 3px;
        margin-left: 100px;


        &:first-child {
          margin-left: 0;
        }

        .ant-tabs-tab-btn {
          color: #999;
          // font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          margin-top: -8%;
          font-family: "Plus Jakarta Sans", sans-serif;
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #002857;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .settingsPage {
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-tab {
          margin-left: 27px;

          .ant-tabs-tab-btn {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.settings-card-dd div {
  padding: 0px;
}