.loginForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 70px;
  .formHead {
    color: #002857;
    font-weight: 500;
    font-size: 18px;
    text-align: flex-start;
  }
  .forgotPassword {
    color: #0050bc;
    font-weight: 500;
    cursor: pointer;
    margin-right: -20px;
  }
  .formCondition {
    color: #002857;
    font-weight: 500;
  }
  .loginButton {
    margin-top: 60px;
    width: 450px;
    font-size: 18px;
    border-radius: 5px;
    padding: 10px;
    background-color: #0050bc;
  }
  .signUp {
    color: #002857;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    // margin-left: 50px;
    margin-top: 15px;
    .link {
      color: #0050bc;
      cursor: pointer;
    }
  }
  .orLine {
    color: #999999;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    // margin-left: 40px;
  }
  .ssoButtons {
    // margin-right: 75px;
    // margin-left: 113px;
    margin-top: 35px;
    gap: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid #999999;
    border-radius: 50px;
    button {
      padding: 8px;
      border-radius: 10px;
      img {
        height: 35px;
      }
    }
  }
  .Signin-with-google-text {
    padding-left: 10px;
    color: black;
    font-weight: 500;
  }
  .MuiOutlinedInput-root {
    height: 45px;
    color: #6f7174;
    font-weight: 500;
    border-radius: 10px;
  }
  input {
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px white inset;
    }
  }
}
@media screen and (max-width: 932px) and (min-width: 430px) {
  .loginForm {
    .forgotPassword {
      margin-right: 35px;
    }
  }
}
@media screen and (max-width: 1439px) and (min-width: 1025px) {
  .loginForm {
    .loginButton {
      width: 350px;
    }
    .orLine {
      margin-top: 25px;
    }
    .ssoButtons {
      margin-top: 35px;
      gap: 25px;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 1025px) {
  .loginForm {
    margin-top: 40px;
    .loginButton {
      margin-top: 30px;
      width: 300px;
    }
    .forgotPassword {
      font-size: 14px;
    }
    .orLine {
      margin-top: 20px;
    }
    .ssoButtons {
      margin-top: 25px;
      gap: 20px;
      button {
        img {
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .loginForm {
    margin-top: 60px;
    .loginButton {
      margin-top: 30px;
    }
    .ssoButtons {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 529px) {
  .loginForm {
    .loginButton {
      width: 365px; // Set width to 365px on mobile
      height: 40px; // Set height to 40px on mobile

      font-size: 12px;
    }
    .forgotPassword {
      margin-right: 9px;
      font-size: 12px;
    }
    .textField {
      height: 40px;
      width: 365px;
    }

    .formCondition {
      font-size: 12px;

      margin-left: 10px;
      margin-right: 10px;
    }
    .orLine {
      font-size: 12px;
    }
    .signUp {
      font-size: 12px;
    }
    .Signin-with-google-text {
      font-size: 14px;
    }
    .ssoButtons button img {
      height: 20px;
    }
  }
}
@media screen and (max-width: 339px) {
  .loginForm {
    .loginButton {
      width: 300px; 
      height: 40px; 
      font-size: 12px;
    }
    .forgotPassword {
      margin-right: 4px;
      font-size: 12px;
    }
    .textField {
      height: 40px;
      width: 300px;
    }
  }
}


@media screen and (max-width: 768px) and (min-width: 600px) {
  .loginForm {
    .forgotPassword {
      margin-right: 9px;
    }
    .loginButton {
      width: 365px;
    }
  }
}

@media screen and (max-width: 1440px) and (min-width: 1020px) {
  .loginForm {
    .forgotPassword {
      margin-right: 11px;
    }
  }
}
// @media screen and (max-width: 400px) {
//   .loginForm {
//     .loginButton {
//       width: 300px;
//     }
//   }
// }
