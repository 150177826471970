.modalPlan {
    h3.title {
      color: #002857;
      font-family: "Gotham", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 45px;
    }
  
    .ant-modal {
      width: 800px !important;
  
      .ant-modal-content {
        padding: 5%;
  
        .ant-modal-close-icon {
          color: #002857;
        }
      }
    }
}

.modalDetail {
    h3.title {
        color: #002857;
        font-family: "Gotham", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 20px;
      }
    
      .ant-modal {
        width: 700px !important;
        // min-width: fit-content;
    
        .ant-modal-content {
          padding: 5%;
    
          .ant-modal-close-icon {
            color: #002857;
          }
        }
      }
      .MuiGrid-item {
        padding-top: 0px !important;
      }  
}