.settings-metrics-container {
    max-width: 800px;
    // margin: 0 auto;
    padding: 1%;
  }
  
  .heading {
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
  }
  .css-17jbvi5-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 2px !important;
    min-height: 0.4375em !important;
   
  }