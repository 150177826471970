.segmentBio {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .bioUpperSection {
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .upperSectionMobile {
    background-color: #02afef;
    color: white;
    margin-bottom: 0px;
  }
  .avatarBio {
    margin-left: -10%;
  }
  .feedCentre {
    justify-content: center;
    display: flex;
  }
  .ratingBox {
    display: inline-flex;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 3rem;
    padding: 0.01rem;
    margin-top: 6px;
    p {
      margin-left: 6px;
      line-height: 18px;
      //font-size: 3rem;
    }
    svg {
      width: 30%;
      padding-left: 3px;
      padding-bottom: 2px;
    }
  }

  .badge {
    border-radius: 4px;
    margin: 4px;
    font-size: 16px;
    font-weight: 500;
  }
  .segmentBioGreenBadge {
    background-color: rgba(125, 198, 153, 0.3);
    color: #7dc699;
  }
  .segmentBioBlueBadge {
    background-color: rgba(2, 175, 239, 0.3);
    color: #02afef;
  }
  .bioLowerSection {
    padding: 20px;
  }
  .bioLowerSectionMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 0px;
    margin-top: -10px;
  }
  .bioChipsMobile {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .skeletonAvatar {
    height: 70px;
    width: 70px;
  }
  .skeletonHeading {
    padding: 5px;
    width: 100px;
    border-radius: 15px;
  }
  .skeletonSubHeading {
    padding: 4px;
    width: 60px;
    border-radius: 15px;
  }
  .skeletonBadge {
    margin: 0px !important;
    padding: 16px;
    width: 90px;
    border-radius: 5px;
  }
  .skeletonText {
    width: 60%;
    border-radius: 10px;
  }
}

#scrollbar {
  box-shadow: none;
}
@media only screen and (max-width: 1360px) and (min-width: 1200px) {
  .segmentBio {
    .avatarBio {
      margin-left: 20px !important;
    }
  }
}
