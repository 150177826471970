.sidebar {
  z-index: 1000;
  // max-width: 18.611%;
  list-style: none;
  width: 268px;
  margin: 0;
  background-color: #fff;

  transition: width 0.2s ease-in-out;
  &.open {
    width: 80px;
    .sidebar-list-item {
      justify-content: center !important;
      padding: 0 !important;
    }
    .sidebar-list-item-caption {
      display: none;
    }
    .sidebar-logo {
      padding-left: 20px;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 13px 39px;
    img {
      height: 34px;
      user-select: none;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
    box-sizing: border-box;
    padding: 38px 20px;
    list-style: none;
    margin: 0;
    &-item {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      text-decoration: none;
      padding: 10px 20px;
      width: 100%;
      height: 40px;

      font-style: normal;

      color: #6f7174;
      cursor: pointer;

      gap: 17px;

      &-icon {
        font-size: 1.45em;
      }

      &-caption {
        flex: 1;
        margin: 0;
        user-select: none;
        text-transform: capitalize;
        // font-size: 1.15em;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &-item:hover {
      background: rgba(150, 224, 255, 0.25);
      border-radius: 10px;

      color: #02afef;
    }
  }

  &.tablet {
    // width: 23.4375%;
    // max-width: 23.4375%;
    width: 200px;

    &.open {
      // width: 8.984375% !important;
      width: 60px;
      .sidebar-list {
        // padding: 50px 18px;
        padding: 30px 10px;
        text-align: left;
        &-item {
          padding: 8px 16px;
        }
      }
    }
    .sidebar-logo {
      padding: 20px 0;
      justify-content: center;
      img {
        height: 24px;
        user-select: none;
      }
    }
    .sidebar-list {
      padding: 50px 18px;
      text-align: left;
      &-item {
        // font-size: .938em;
        gap: 10px;
        padding: 8px 16px;
        &-icon {
          font-size: 1.25em;
          width: 22px;
        }
      }
    }
  }

  &.mobile {
    display: none;
    &.open {
      display: block;
      position: fixed;
      width: 75%;
      // max-width: 60vw;
      height: 100vh;
      top: 0;
      left: 0;

      transform: translateX(0);
      animation: slide-in 0.3s forwards;
      .sidebar-logo {
        padding: 17px 40px;
        img {
          width: 117px;
          height: 23px;
          user-select: none;
        }
      }
      .sidebar-list {
        padding: 26px 11px;
        &-item {
          justify-content: flex-start !important;
          padding: 8px 25px !important;
          gap: 12px;
          &-icon {
            font-size: 20px;
          }
          &-caption {
            font-size: 14px;
            display: block;
          }
        }
      }
    }
  }
}

.mobile-sidebar-close {
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  top: 0;
  right: 0;
  width: 110px;
  height: 100vh;
  z-index: 1000;

  transform: translateX(0);
  animation: slide-in 0.3s forwards;
  &-btn {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    cursor: pointer;
    position: fixed;
    top: 15px;
    right: 32px;
    margin: 0;
  }
  &.show {
    display: none;
  }
}

.active {
  background: rgba(150, 224, 255, 0.25);
  border-radius: 10px;

  color: #02afef;
}

@keyframes slide-in {
  from {
    transform: translateX(-40vw);
  }
  to {
    transform: translateX(0);
  }
}
@media (min-width: 430px) and (max-width: 768px) {
  .mobile-sidebar-close {
    width: 192px;
  }
}
