.segmentBio {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 245px;
  .bioUpperSection {
    // padding: 10px 20px 10px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #c0ebfb;
    color: #002857;
  }
  .upperSectionMobile {
    background-color: #02afef;
    color: white;
  }
  .avatarBio {
    width: 80px;
    height: 80px;
    margin:0px !important;
    border: 5px solid white;
  }
  .ratingBox {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 2px 4px;
    p {
      font-size: 18px;
      margin-left: 5px;
      line-height: 20px;
    }
    svg {
      height: 87%;
    }
  }
  .emailDetails {
    margin-left:-12%;
      line-height: 20px;
    }
  .bioContact {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
  .badge {
    border-radius: 4px;
    margin: 4px;
     font-size: 16px;
    font-weight: 500;
  }
  .segmentBioGreenBadge {
    background-color: #e5f4eb;
    color: #7dc699;
    height: 27px;
  }
  .segmentBioBlueBadge {
    background-color: #cceffc;
    color: #02afef;
    height: 27px;
  }
  .bioLowerSection {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 0px;
    margin-top: -30px;
  }
  .bioChips {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 2px;
  }
  .skeletonAvatar {
    margin-top: 15px;
    height: 90px;
    width: 90px;
  }
  .skeletonHeading {
    padding: 6px;
    width: 100px;
    border-radius: 15px;
  }
  .skeletonRating {
    margin: 0px !important;
    padding: 10px;
    width: 40px;
    border-radius: 5px;
  }
  .skeletonBadge {
    margin: 0px !important;
    padding: 16px;
    width: 80px;
    border-radius: 5px;
  }
  .skeletonLowerSection {
    gap: 0px;
    margin-top: -40px;
  }
}