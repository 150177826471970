:root {
  --icon-bk-color: #ffffff;
}
.summaryCard {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 21px 22px 13px 22px;
  justify-content: space-between;

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-detail {
      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #6f7174;
        margin: 0;
        margin-bottom: 16px;
      }
      &-value {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #002857;
        margin: 0;
      }
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--icon-bk-color);
    }
  }
  &-viewLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #02afef;
    align-self: flex-end;
    margin-top: 32px;
    .arrow-icon {
      
      font-size: 22px;
    }
  }

  &.tablet {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 16px 12px 10px;
    height: auto;
    min-width: 360px;
    .summaryCard-info {
      align-items: start;
      &-detail {
        &-title {
          font-size: 14px;
          margin-bottom: 14px;
        }
        &-value {
          font-size: 18px;
        }
      }
      &-icon {
        width: 36px;
        height: 36px;
      }
    }

    .summaryCard-viewLink {
      font-size: 10px;
      margin-top: 15px;
    }
  }

  &.mobile {
    width: 100%;
    // height: 140px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    padding: 16px 23px 16px 26px;
    min-width: 261px;
    .summaryCard-info {
      align-items: start;
      &-detail {
        &-title {
          font-size: 14px;
          margin-bottom: 5px;
        }
        &-value {
          font-size: 16px;
        }
      }
      &-icon {
        width: 40px;
        height: 40px;
      }
    }

    .summaryCard-viewLink {
      font-size: 10px;
      margin-top: 10px;
    }
  }
}
