.payment-modal {
  width: 100% !important;
  height: 100vh !important;
  max-width: 100% !important;
  z-index: 3147483647;
  top: 0px;

  .ant-modal-content {
    border-radius: 0px;
    height: 100vh !important;
  }

  .ant-modal-title {
    img {
      height: 27px;
    }    
  }

  .payment-history-container {
    max-width: 100%;
    width: 744px;
    max-width: 744px;
    position: absolute;
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);    

    .payment-history {
      display: flex;
      justify-content: flex-start;	
      flex-direction: column;
      align-items: flex-start;
    }

    .payment-history-title {
      color: #002857;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .payment-history-subtitle {
      color: #999;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .plan-info {
      border-radius: 10px;
      border: 3px solid #02AFEF;
      width: calc(100% - 36px);
      display: flex;
      justify-content: flex-start;	
      flex-direction: row;
      padding: 20px 15px;
  
      .plan-desc {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        color: #999;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;        

        .name {
          color: #02AFEF;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      
      .plan-price {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        color: #002857;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;        
      }
    }

    .payment-history-table {

      .payment-history-table-header {
        color: #002857;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;        
        display: flex;
        justify-content: flex-start;	
        flex-direction: row;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .payment-history-item {
        color: #999;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: flex-start;	
        flex-direction: row;
        padding-top: 17px;
        padding-bottom: 17px;

        .col-total {
          color: #002857;
          font-weight: 600;
        }

        .col-invoice {
          a, img {
            display: flex;
            justify-content: flex-start;
            align-items: center;        
            gap: 10px;
            color: #999;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;            
          }
        }

        .last-four {
          display: flex;
          align-items: center;  
          gap: 5px;
        }
      }

      .history-pm {
        display: flex;
        justify-content: flex-start;	
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      .odd {
        background: rgba(200, 200, 206, 0.10);
      }

      .row {
        border-bottom: 0.5px solid #C8C8CE;
      }

      .col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
      }

      .col-date {
        width: calc(15% - 20px);
      }

      .col-desc {
        width: calc(30% - 20px);
      }

      .col-pm {
        width: calc(25% - 20px);
      }

      .col-invoice {
        width: calc(20% - 20px);
      }

      .col-total {
        width: calc(10% - 20px);
        justify-content: flex-end;
      }
    }

    .payment-method-button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: #002857;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;   
      display: flex;
      align-items: center;
    }

    .payment-back {
      cursor: pointer;
      color: #02AFEF;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;        
      display: flex;
      align-items: center;
    }    

  }
}

@media (max-width: 768px) {
  .payment-modal {  
    .payment-history-container {
      max-width: calc(100% - 20px);
      width: calc(100% - 20px);

      .payment-history {
        padding-left: 10px;
        padding-right: 10px;
      }

      .payment-history-table {

        .payment-history-item {

          .col-invoice {
            img {
              display: none;
            }
          }

          .last-four {
            display: none;
          }
        }

        .col-date {
          width: calc(20% - 20px);
        }
  
        .col-desc {
          width: calc(30% - 20px);
        }
  
        .col-pm {
          width: calc(15% - 20px);
        }
  
        .col-invoice {
          width: calc(20% - 20px);
        }
  
        .col-total {
          width: calc(15% - 20px);
          justify-content: flex-end;
        }
      }
  
    }
  }
}

