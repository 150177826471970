.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &-main {
    flex: 1;
    display: flex;

    &-body {
      // flex: 1;
      // display: flex;
      // flex-direction: column;
      transition: width 0.2s ease-in-out;
      width: calc(100% - 268px);

      &-content {
        flex: 1;
        display: flex;
        align-items: start;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 30px 0 30px;
        height: calc(100vh - 60px - 75px);
        overflow: auto;
      }

      &.expended {
        width: calc(100% - 80px);
      }
    }
  }

  &.tablet {
    &-main {
      &-body {
        &.expended {
          width: calc(100% - 60px);
        }

        &-content {
          // padding: 29px 0 0 30px;
          padding: 24px 20px;
        }
      }
    }
  }
  &.mobile {
    &-main {
      &-body {
        width: 100%;
        padding: 16px;
        transition: none;
        &.expended {
          width: 100%;
        }
        &-content {
          padding: 0px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .layout {
    &-main {
      &-body {
        &.expended {
          width: calc(100% - 60px);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .layout {
    &-main {
      &-body {
        width: 100%;
        &.expended {
          width: 100%;
        }
        &-content {
          padding: 0px;
        }
      }
    }
  }
}
