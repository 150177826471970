.editPersonaPopup {
  .ant-modal-content {
    padding: 33px 58px;
    max-width: 477px;
    width: 100%;

    .ant-modal-close {
      .ant-modal-close-x {
        svg {
          fill: #000;
        }
      }
    }
  }
  
  .ant-modal-body {
    h3 {
      margin: 0 0 21px;
      color: #002857;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .list {
      border-radius: 5px;
      border: 1px solid #000;
      padding: 16px 14px;
      box-sizing: border-box;
      max-height: 352px;
      overflow: auto;

      .persona {
        margin-bottom: 25px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
