.createAccount {
    background-image: linear-gradient(#b8e3f3, #e9f2f5);
    padding: 0px !important;
    min-height: 100vh;
    position: relative;
    .createAccountContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 22px 200px 70px;
      margin-bottom: 100px;
      .logoContainer {
        position: relative;
        top: 44px;
        z-index: 1;
        .logo {
          height: 100px;
        }
      }
  
      .createAccountCard {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px 0px;
        // width: 600px;
        .cardHeading {
          color: #002857;
          font-weight: 600;
          margin-bottom: 5px;
          margin-top: 25px;
        }
        .cardUpperSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          .cardSubHeading {
            color: #999999;
            font-weight: 500;
            margin-bottom: 30px;
            font-size: 16px;
            font-family: "Gotham" !important;
          }
          .divider {
            color: #c8c8ce;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          button {
            border-color: #c8c8ce;
            padding: 10px;
            width: 100%;
            border-radius: 10px;
            margin-bottom: 15px;
            justify-content: center;
            img {
              margin-right: 20px;
            }
          }
        }
        .cardForm {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .formHead {
            color: #002857;
            font-weight: 500;
            font-size: 18px;
            text-align: flex-start;
          }
          .formCondition {
            color: #002857;
            font-weight: 500;
            font-size: 14px;
          }
          .link {
            color: #02afef;
            text-decoration: none;
          }
          .errorMsg {
            margin-top: -10px;
            margin-left: 14px;
          }
          .formButtons {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            button {
              padding: 8px;
              width: 45%;
              border-radius: 10px;
            }
          }
          .MuiOutlinedInput-root {
            height: 45px;
            color: #6f7174;
            font-weight: 500;
            border-radius: 10px;
          }
          input {
            &:-webkit-autofill {
              -webkit-box-shadow: 0 0 0 100px white inset;
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 900px) {
    .createAccount {
      .createAccountContainer {
        .createAccountCard {
          // padding: 60px 40px;
        }
      }
    }
  }
  
  @media only screen and (max-width: 767px) {
    .createAccount {
      background-image: none;
      background-color: white;
      .createAccountContainer {
        // padding: 30px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
       
        .logoContainer {
          position: relative;
          top: auto;
          z-index: auto;
          margin-bottom: 20px;
          .logo {
            height: 65px;
          }
        }
  
        .createAccountCard {
          border-radius: 20px;
          box-shadow: none;
          // padding: 0px;
          margin-bottom: 20px;
          // width: 100%;
        }
      }
    }
    .cardHeading {
      color: #002857;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 25px;
      // font-size: 16px !important; 
    }
  
    .cardSubHeading {
      color: #999999;
      font-weight: 500 !important;
      margin-bottom: 30px;
      font-size: 14px !important;
      font-family: "Gotham" !important; 
    }
    .mobileCircle {
      r: 8;
  }
  }
  @media only screen and (max-width: 768px) {
    .createAccount {.createAccountContainer {.createAccountCard {.cardUpperSection{
  .cardSubHeading{
    font-size: 14px;
  }}}}}}
  @media only screen and (max-width: 339px) {
    .createAccount {
      .createAccountContainer {
       padding:0px;
      }
    }
  }