[class~="ant-carousel"] {
    [class~="slick-slider"] {
      li button {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        color: 002857 !important;
        background-color: white;
        border: 2px solid #002857 !important;
        border-color: #002857 !important;
      }
      li.slick-active button {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        color: #002857 !important;
      }
      .slick-dots li button:before {
        background-color: #002857 !important;
      }
      .slick-dots li.slick-active button:before {
        background-color: #000 !important;
      }
    }
  }
  .customer-insights {
    .ant-carousel {
      .slick-dots {
        bottom: 0px !important;
      }
    }
  }