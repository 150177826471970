.infoform {
    background-color: white;
    width: 700px;
    border-radius: 20px;
    height:600px ;
    margin-top: 40px;
}
.SubHeading {
  color: #999999;
  font-weight: 500;
  padding-bottom: 20px;
  text-align: center;
}
    
  .Heading {
    color: #002857;
    font-size: 16px;
    padding-top: 20px;
    text-align: center;
    
  }
  .FormHead {
    color: #002857;
      font-size: 18px;
  }
  