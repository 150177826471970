.header {
  display: flex;
  align-items: center;
  padding: 17px 36px;
  height: 26px;
  // justify-content: space-between;
  justify-content: start;
  background-color: #002857;

  .header-toggle {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 22px;
    font-stretch: 100% !important;
    cursor: pointer;
    width: 30px;
  }

  .header-search {
    margin-left: auto;
    margin-right: 0;

    .btnDropdown {
      background: transparent;
      color: rgba(255, 255, 255, 0.6);
      font-family: Gotham;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      min-width: 291px;
      text-align: left;
      display: inline-flex;
      align-items: center;
      height: 42px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.6);

      svg {
        margin-left: auto;
      }
    }
  }

  .header-contorls {
    display: inline-flex;
    align-items: center;
    // margin-left: auto;

    &-serachForm {
      position: relative;
      width: 283px;

      input {
        background: rgba(255, 255, 255, 0.1) !important;
      }

      input[type="text"] {
        display: flex;
        align-items: center;
        height: auto;
        width: 100%;
        border-radius: 8px;
        border: none;
        padding: 13px 13px 13px 43px;
        box-sizing: border-box;
        overflow: hidden;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
      }
      input[type="text"]:focus {
        border: none;
        outline: none;
      }
      input[type="text"]::placeholder {
        font-style: normal;
        color: rgba(255, 255, 255, 0.4);
      }

      .search-icon {
        font-size: 18px;
        position: absolute;
        top: 12px;
        left: 13px;
        color: #fff;
      }
    }
    &-list {
      list-style: none;
      display: flex;
      width: auto;
      justify-content: space-between;
      // align-items: center;
      margin: 0;
      padding: 0;

      li {
        // margin-left: 27px;
      }

      &-loginUserName {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        margin: 0;
        padding: 0;

        background-color: #fff;
        color: #002857;
        text-transform: uppercase;
        margin: 0;
        cursor: pointer;
        font-weight: 600;

        font-size: 10px;
      }
      &-notification {
        &-icon {
          color: #fff;
          font-size: 22px;
          cursor: pointer;
          margin: 0;
          padding: 0;
        }
      }
      &-logout {
        margin: 0;
        padding: 0;
        &-icon {
          cursor: pointer;
          color: white;
          font-size: 22px;
        }
      }
    }
  }

  &.tablet {
    // padding: 17px 32px 17px 39px;
    padding: 15px 20px;
    height: 30px;
    gap: 20px;

    .header-toggle {
      font-size: 16px;
    }

    .header-search {
      // margin: 0 auto;
      margin-left: 0px;
    }

    .header-contorls {
      // width: 61.78343949044586%;
      margin-left: auto;

      &-serachForm {
        // width: 45.15463917525773%;
        width: 100%;
        max-width: 200px;
        input[type="text"] {
          padding: 9px 10px 9px 54px;
          border-radius: 7px;
        }

        .search-icon {
          top: 6px;
          left: 24px;
        }
      }
      &-list {
        // width: 21.44329896907216%;
        width: auto;
        &-loginUserName {
          width: 20px;
          height: 20px;
          font-size: 8px;
        }
        &-notification {
          font-size: 20px;
        }
        &-logout {
          font-size: 20px;
        }
      }
    }
  }
  &.mobile {
    display: flex;
    align-items: center;

    height: 30px;
    background-color: #002857;
    // justify-content: space-between;

    .header-toggle {
      color: #fff;
      font-size: 20px;
    }

    .header-logo {
      flex: 1;
      img {
        margin-right: 60%;
        margin-top: 0px;

        // height: 30px;
      }
    }
    .header-user {
      display: none;
      // display: inline-flex;
      // align-items: center;
      .ant-btn {
        margin-right: 19px;
      }
      &-info {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        color: #002857;
        font-size: 12px;
        font-weight: 700;
        background-color: #fff;
        border-radius: 50%;
        text-transform: uppercase;
        margin: 0;
      }

      &-dropdown {
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-sizing: border-box;
        padding: 12px 4px;
        position: absolute;
        background-color: #fff;
        border-radius: 5px;
        color: #6f7174;
        top: 40px;
        right: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        z-index: 1000;
        &-item {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 6px 20px;
          gap: 10px;
          margin: 0;
          text-decoration: none;
          color: #6f7174;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          border-radius: 5px;
          font-family: "Lato";

          &:hover {
            background-color: #ddd;
          }

          &-logout-icon {
            transform: rotate(180deg);
            font-weight: 700;
          }
        }
      }
    }
  }
}

.profileMenus {
  width: 269px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .ant-popover-content {
    .ant-popover-inner {
      padding: 0;
    }

    .ant-switch {
      background: #999999;

      &.ant-switch-checked {
        background: #002857;
      }
    }

    .ant-list {
      padding-bottom: 10px;
      .ant-list-item {
        padding: 9px 14px 9px 30px;
        border-bottom: none;
        &:first-child {
          border-bottom: 1px solid #c8c8ce;
          padding: 14px 14px 14px 30px;
          margin-bottom: 4px;
        }
        .ant-list-item-meta {
          align-items: center;
        }
        h4.ant-list-item-meta-title {
          color: #002857;
          font-family: Gotham;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0 0 9px;
        }

        h5 {
          color: #002857;
          // font-family: Plus Jakarta Sans;
          margin: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        h6 {
          color: #707070;
          font-family: Gotham;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin: 0;
        }

        h4 {
        }
      }
    }
  }
}
