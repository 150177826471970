.checkIn {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .input-autofill {
    background-color: white !important;
  }

  p {
    margin: 0;
  }
  h1 {
    margin: 0;
  }
  &-main {
    display: flex;
    min-height: calc(100vh - 100px);
    &-left {
      width: 50%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 3rem 6rem;
      &-header {
        &-logo {
          text-align: center;
          img {
            height: 75px;
          }
          margin-bottom: 30px;
        }
        margin-bottom: 40px;
        &-title {
          font-weight: 900;
          font-size: 20px;
          color: #002857;
          margin-bottom: 0.875rem !important;
        }
        &-subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          color: #6f7174;
        }
      }
      &-main {
        display: flex;
        flex-direction: column;
        .checkIn-form {
          .input-group {
            display: flex;
            flex-direction: column;
            gap: 0.875rem;
            width: 100%;
            margin-bottom: 20px;

            .form-label {
              display: flex;
              align-items: center;
              justify-content: space-between;

              font-style: normal;
              font-weight: 500;
              font-size: 1.125rem;
              color: #002857;

              .forget-password {
                text-decoration: none;
                color: #0050bc;
                text-align: right;
                font-size: 1rem;

                font-weight: 500;
              }
            }

            .form-input {
              box-sizing: border-box;
              padding: 17px 15px;
              border: 1px solid #c8c8ce;
              border-radius: 10px;
              height: 50px;
              font-size: 1rem;
            }
          .checkIn-main-left-header-subtitle {
            font-family: 'Gotham',sans-serif;
            margin-top: 0%;
          }
          
        
            input.form-input::placeholdr {
              font-style: normal;
              font-weight: 600;
              font-size: 1rem;
              color: #c8c8ce;
              text-transform: capitalize;
            }
          }
          .input-checkbox {
            display: flex;
            gap: 9px;

            margin-bottom: 16px;
           

            &-label {
              flex: 1;
              font-style: normal;
              font-weight: 500;
              font-size: 1rem;
              user-select: none;
              cursor: pointer;

              color: #002857;
            }
            input[type="checkbox"] {
              width: 20px;
              height: 20px;

              border: 1px solid #6f7174;
              border-radius: 2px;
            }
          }
          .checkInBtn {
            width: 100%;
            height: 50px;
            border-radius: 10px;
            background: #0050bc;
            border: none;
            margin-top: 40px;

            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            color: #fff;
            cursor: pointer;
          }
          .checkInBtn:hover {
            opacity: 0.7;
          }
        }
      }
    }
    &-right {
      flex: 1;
      background-image: url("../../assets/img/check-in-image.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      &-overide {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      }
    }
    &.tablet {
      .checkIn-main-left {
        width: 100%;
        padding: 2rem 2rem;
        min-height: 1100px;
        &-header {
          &-logo {
            margin-bottom: 60px;
          }
          &-title {
            margin-bottom: 1rem;
          }
          &-subtitle {
            font-size: 15px;
          }
          margin-bottom: 10%;
        }
        &-main {
          .checkIn-form {
            .input-group {
              gap: 20px;
              &:first-child {
                margin-bottom: 75px;
              }
              &:nth-child(2) {
                margin-bottom: 40px;
              }

              .form-label {
                font-size: 16px;
              }
              .form-input {
                height: 60px;
                border-radius: 5px;
              }
              .input-checkbox {
                gap: 24px;
              }
              .checkInBtn {
                height: 60px;
                font-size: 16px;
                margin-bottom: 42px;
              }
            }
          }
        }
      }
      .checkIn-main-right {
        display: none;
      }
    }
    &.mobile {
      // Updated SCSS code
      min-height: calc(100vh - 62px);

      .checkIn-main-left {
        width: 100%;
        padding: 43px 26px;
        &-header {
          &-logo {
            margin-bottom: 40px;
          }
          margin-bottom: 64px;
          &-title {
            font-size: 16px;
            margin-bottom: 11px;
          }
          &-subtitle {
            font-size: 12px;
          }
          margin-bottom: 40px;
        }
        &-main {
          .checkIn-form {
            .input-group {
              gap: 14px;

              .form-label {
                font-size: 12px;
                .forget-password {
                  font-size: 12px;
                }
              }
              .form-input {
                height: 40px;
                border-radius: 5px;
                padding: 14px 12px;
                font-size: 12px;
              }
              input.form-input::placeholder {
                font-size: 10px;
              }
            }
            .input-checkbox {
             // gap: 10px;
margin-top: 10px;
              &-label {
                font-size: 12px;
                margin-top: 10px;
              }
              input[type="checkbox"] {
                width: 15px;
                height: 15px;

                border: 1px solid #6f7174;
                border-radius: 2px;
              }
            }
            .checkInBtn {
              height: 40px;
              font-size: 12px;
              border-radius: 5px;
              margin-top: 30px;
            }
            .sign-up {
              &-caption {
                font-size: 12px;
              }
              &-link {
                font-size: 12px;
              }
            }
          }
        }
      }
      .checkIn-main-right {
        display: none;
      }
    }
  }

  .checkIn-form {
    width: auto;
    .ant-form-item-label {
      label {
        color: #002857;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &::before {
          display: none !important;
        }
      }
    }

    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-input {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          padding: 12px 15px;
        }

        .ant-input-affix-wrapper {
          // color: #c8c8ce;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          padding: 12px 15px;

          .ant-input {
            padding: 0;
          }
        }

        .ant-checkbox-wrapper {
          > span {
            color: #002857;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
    .checkIn {
      .checkIn-form {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-checkbox-wrapper {
              > span {
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
    .ant-form-item-explain-error {
      color: #de2b2b;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    .ant-form-item {
      // margin-bottom: 36px;
    }

    .ant-btn {
      border-radius: 10px;
      background: #0050bc;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      height: 50px;
    }
    // :where(.css-dev-only-do-not-override-1uq9j6g).ant-checkbox  {
    //   align-self: unset;
    
    // }
  }
}
// :where(.css-dev-only-do-not-override-1uq9j6g).ant-checkbox .ant-checkbox-inner {
//   margin-top: -2px;
//   margin-bottom: 2px;
// }

