.payment-modal {

  .ant-modal-content {
    border-radius: 0px;
    height: 100vh !important;
 

  }

  .ant-modal-title {
    img {
      height: 27px;
    }    
  }
 
 
}

.ant-modal .ant-modal-content {
padding: 36px;
margin-left: 30px;
margin-right: 30px;
}


@media (max-width: 768px) {
  .ant-modal-root .ant-modal-wrap {
    overflow: hidden !important;
  }
  
  .payment-modal {  
   
    .ant-modal-content {
      border-radius: 0px;
      height: 100vh !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
  
    }
    .payment-history-container {
      max-width: calc(100% - 20px);
      width: calc(100% - 20px);

      .payment-history {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}