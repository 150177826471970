// .settingOrganization {
//   margin-top: 30px;

//   .orgHeader {
//     display: flex;
//     gap: 20px;
//     align-items: start;

//     .btnLocation {
//       margin-left: auto;
//       display: inline-flex;
//       gap: 9px;
//       align-items: center;
//       justify-content: center;
//       border-radius: 5px;
//       background: #002857;
//       border: none !important;
//       height: 35px;
//       width: 127px;
//       color: #fff;
//       font-family: "Gotham", sans-serif;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 700;
//       line-height: normal;
//       margin-left: auto;

//       &:hover {
//         background: #002857;
//         border: none !important;
//         opacity: 0.7;
//       }
//     }
//   }
// }

// .modalLocation {
//   .headerPopup {
//     text-align: center;

//     .userDetail {
//       display: inline-flex;
//     }

//     h3.title {
//       color: #002857;
//       font-family: "Gotham", sans-serif;
//       font-size: 20px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: normal;
//       margin: 0 0 47px;
//     }
//   }

//   .ant-modal {
//     width: 950px !important;

//     .ant-modal-content {
//       padding: 65px 70px 59px;

//       .ant-modal-close-icon {
//         color: #002857;
//       }
//     }
//   }
// }

// .avatar-edit-container {
//   position: relative;
//   display: inline-block;
// }

// .avatar-edit-container .edit-icon {
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   background: rgba(255, 255, 255, 0.75); // Semi-transparent background
//   border-radius: 50%;
//   padding: 4px;
//   cursor: pointer;
// }

.location {
  margin-top: 30px;
  .locationHeader {
    display: flex;
    gap: 24px;

    .stats {
      p {
        color: #002857;
        font-family: "Gotham", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0 0 32px;
      }

      .viewStats {
        display: flex;
        gap: 56px;
      }

      .statItem {
        h6 {
          color: #707070;
          font-family: "Gotham", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin: 0 0 14px;
          text-align: center;
        }

        h2 {
          color: #002857;
          font-family: "Gotham", sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
          text-align: center;
        }
      }

      .ant-input-group-wrapper {
        margin-top: 22px;
        height: 35px;

        .ant-input-wrapper {
          input {
            height: 35px;
            font-family: "Gotham", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            border-color: #c8c8ce;

            &::placeholder {
              color: #707070;
            }
          }

          .ant-input-group-addon {
            .ant-btn {
              border-left: 0;
              height: 35px;
              border-color: #c8c8ce;
            }
          }
        }
      }
    }

    .btnInvite {
      border-radius: 5px;
      background: #002857;
      border: none !important;
      height: 35px;
      width: 120px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: auto;

      &:hover {
        background: #002857 !important;
        color:white !important;
        border: none !important;
        opacity: 0.7;
      }
    }
  }

  .ant-pagination {
    justify-content: center;

    .ant-pagination-item {
      border-radius: 2px;
    }

    .ant-pagination-item-active {
      background: #002857;
      border-color: #002857 !important;
      color: #fff !important;

      a {
        color: #fff;
      }
    }
  }

  .ant-table {
    margin-top: 22px;
    height: 578px;

    .action {
      display: flex;
      align-items: center;
      justify-content: end;

      .btnInvite {
        color: #0050bc;
        font-family: "Gotham", sans-serif;
        // font-family: "Plus Jakarta Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0;
        border: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: auto !important;
        min-height: 0 !important;

        &:hover {
          color: #0050bc;
          background: none !important;
          opacity: 0.7;
        }
      }

      .btnMenu {
        padding: 0;
        height: auto !important;
        min-height: 0 !important;
        color: #002857;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        margin-left: auto;
        width: 28px;
        height: 24px;
        border-radius: 2px;

        &:active {
          background: #002857;
          color: #fff;
        }

        &:focus,
        &:hover {
          background: #002857;
          color: #fff;
        }
      }
    }

    .ant-table-thead {
      .ant-table-cell {
        color: #707070;
        font-family: "Gotham", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        background: #fff;
        padding: 19px 0;
        border-color: #707070;

        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        color: #707070;
        font-family: "Gotham", sans-serif;
        // font-family: "Plus Jakarta Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        padding: 19px 0;
        border: none;
      }
    }
    .viewLocation {
      display: inline-flex;
      align-items: center;
      gap: 17px;

      .locationInfo {
        h4 {
          color: #707070;
          font-family: "Gotham", sans-serif;
          // font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
          margin: 0 0;
          display: inline-flex;
          align-items: center;
          gap: 7px;

          .ant-tag {
            color: #002857;
            font-family: "Gotham", sans-serif;
            font-size: 9px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 3px;
            border: none;
            border-radius: 2px;
            background: #d9d9d9;
          }
        }
        p {
          color: #707070;
          font-family: "Gotham", sans-serif;
          // font-family: "Plus Jakarta Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }
}

.modalInvite {
  h3.title {
    color: #002857;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 60px;
  }

  .ant-modal {
    width: 608px !important;

    .ant-modal-content {
      padding: 122px 137px 87px;

      .ant-modal-close-icon {
        color: #002857;
      }
      input.role[disabled] {
        background-color: #dfdfdf !important;  /* Light grey background */
        color: #4d4d4d !important; /* Default grey text color for disabled input */
        
      }
    }
  }

  .formProfile {
    .ant-form-item {
      .ant-form-item-label {
        color: #002857;
        // font-family: Gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 18px;

        .ant-form-item-required {
          &::before {
            display: none;
          }
          &::after {
            display: inline-block;
            margin-inline-end: 4px;
            line-height: 1;
            content: "*";
            visibility: unset;
            color: #de2b2b;
            font-family: Gotham;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .ant-input {
        border-radius: 10px;
        border: 1px solid #c8c8ce;
        background: #fff;
        height: 40px;
        color: #707070;
        // font-family: Gotham;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .ant-select {
        height: 40px;

        .ant-select-selector {
          border-radius: 10px;
          border: 1px solid #c8c8ce;
          background: #fff;

          color: #707070;
          // font-family: Gotham;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .ant-select-selection-item {
          color: #707070;
          // font-family: Gotham;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .actions {
      display: flex;
      gap: 36px;
      margin-top: 20px;
      justify-content: center;

      .btnCancel {
        border-radius: 20px;
        background: #999;
        border: none !important;
        height: 40px;
        width: 150px;
        color: #fff;
        // font-family: Gotham;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &:hover {
          opacity: 70;
        }
      }

      .btnSave {
        border-radius: 20px;
        background: #002857;
        border: none !important;
        height: 40px;
        width: 150px;
        color: #fff;
        // font-family: Gotham;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &:hover {
          opacity: 70;
        }
      }
    }
  }
}

.modalInvite.modalCreateUser {
  .ant-modal {
    width: 849px !important;

    .ant-modal-content {
      padding: 51px 40px 59px;

      .ant-modal-close-icon {
        color: #002857;
      }
    }
  }
}
.ant-select-item-option {
  color: grey !important; 
}
.ant-select-item-option-selected {
  background-color: rgba(169, 169, 169, 0.3) !important; 
  color:  rgba(169, 169, 169, 0.9) !important; 
}

