.desktopCard {
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  .css-137p82i-MuiButtonBase-root-MuiButton-root {
    padding: 0px; }
}
.journeyCardDesign {
  padding: 25px;
  
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .connectionSection {
    .cardConnection {
      background-color: white;
      text-align: center;
      border-radius: 10px;
      box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.3);
      padding: 20px;
      margin: 20px;
      .cardHeading {
        font-size: 15px;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }
  .buyingJourneyImgBox {
    display: flex;
    justify-content: center;
    img {
      margin-top: 15%;
    }
  }
  .serviceSection {
    .cardService {
      .serviceCircle {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        height: 80px;
        width: 80px;
        padding: 20px;
      }
      margin: 20px;
    }
  }
}

@media only screen and (max-width: 495px) and (min-width: 320px) {
  .journeyCardDesign {
    .connectionSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 5px;
      .cardConnection {
        width: 30%;
        margin: 5px;
      }
    }
    .serviceSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 20px;
      .cardService {
        margin: 8px;
      }
    }
    .buyingJourneyImgBox {
      img {
        margin-top: 5%;
      }
    }
  }
}

@media only screen and (max-width: 891px) and (min-width: 496px) {
  .journeyCardDesign {
    .connectionSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 20px;
      .cardConnection {
        width: 30%;
        margin: 8px;
      }
    }
    .serviceSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 20px;
      .cardService {
        display: flex;
        justify-content: center;
        width: 30%;
        margin: 8px;
      }
    }
    .buyingJourneyImgBox {
      img {
        margin-top: 5%;
      }
    }
  }
}

@media only screen and (max-width: 1535px) and (min-width: 892px) {
  .journeyCardDesign {
    .connectionSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 20px;
      .cardConnection {
        width: 40%;
        margin: 8px;
      }
    }
    .serviceSection {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .cardService {
        margin: 20px auto;
      }
    }
    .buyingJourneyImgBox {
      img {
        margin-top: 5%;
      }
    }
  }
}
