.customer-insights {
    .sliderBox {
      // margin-bottom: 40px;
    }
    .ant-carousel {
      .slick-track {
        gap: 30px;
        padding-bottom: 35px;
      }
      .slick-list {
           /* padding: 0px 10px; */
    margin-left: 0%;
        // margin-right: -10px;
      }
      .slick-dots {
        bottom: 0;
        li {
          width: 8px;
          height: 8px;
          button {
            height: 100%;
            border-radius: 50%;
          }
        }
        li.slick-active {
          button {
            background: #002857 !important;
          }
        }
      }
    }
  }