.payment-modal {
  width: 100% !important;
  height: 100vh !important;
  max-width: 100% !important;
  z-index: 3147483647;
  top: 0px;

  .ant-modal-content {
    border-radius: 0px;
    height: 100vh !important;
  }

  .ant-modal-title {
    img {
      height: 27px;
    }    
  }

  .payment-method-container {
    max-width: 100%;
    width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);    

    .add-payment-method, .update-payment-method {
      display: flex;
      justify-content: flex-start;	
      flex-direction: column;
      align-items: flex-start;
    }

    .payment-methods {
      display: flex;
      justify-content: flex-start;	
      flex-direction: column;
      align-items: center;  
    }  

    .payment-method-logo {
      margin-bottom: 20px;
    }

    .payment-method-title {
      color: #002857;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .payment-method-description {
      color: #002857;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;        
    }

    .payment-method-button {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: #002857;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;   
      display: flex;
      align-items: center;
    }

    .payment-back {
      cursor: pointer;
      color: #02AFEF;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;        
      display: flex;
      align-items: center;
    }    

    .payment-form {

      .payment-method-item {
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        padding: 5px 10px 5px 10px;     
        color: #002857;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: calc(100% - 22px);
  
        .payment-method-info {
          display: flex;
          justify-content: flex-start;	
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
  
      .ant-input-outlined:focus,
      .ant-input-outlined:active,
      .ant-input-outlined:hover,
      .ant-input-number-input:focus,
      .ant-input-number-input:active,
      .ant-input-number-input:hover,
      .ant-input-number:focus,
      .ant-input-number:not(:focus),
      .ant-input-number:active,
      .ant-input-number:hover {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;        
      }

      .row-half {
        display: flex;
        justify-content: flex-start;	
        flex-direction: row;
        max-width: 100%;
        gap: 20px;    
      }

      .ant-form-item {
        margin-bottom: 15px;

        &.half {
          width: 50%;

          .ant-form-item-label, .ant-form-item-control {
            width: 100%;
            max-width: 100%;
          }
        }
      }

      .ant-form-item-label {
        label {
          color: #002857;
        }
      }
      
      .StripeElement {
        margin: 0px;
        box-sizing: border-box;
    
        height: 32px;
        line-height: 32px;
    
        padding: 7px 11px;
    
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        background-color: white;
        text-align: center;
        color: #707070;
        
        &.Details {
          display: block;
          width: calc(100% - 40px);
          margin: 10px 20px 20px;
          font-family: sans-serif;
          font-size: 0.9em;
          
    
          &::placeholder {
            color: #757575;
            opacity: 1;
          }
    
          &:focus {
            outline: none;
          }
    
          &:disabled {
            color: #cfd7df;
          }
        }
      }      
    }

    .payment-input.ant-input-number {
      width: 100% !important;
    }

    .payment-input, .payment-input.ant-input-number {
      font-family: sans-serif;
      color: #000;
    }

    .payment-input::placeholder,
    .payment-input.ant-input-number::placeholder {
      color: #707070;
    }

    .payment-method-item {
      border-radius: 5px;
      border: 1px solid #999;
      padding: 10px 10px 10px 20px;     
      color: #002857;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: calc(100% - 42px);

      .payment-method-info {
        display: flex;
        justify-content: flex-start;	
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .last-four {
          display: flex;
          align-items: center;  
          gap: 5px;
        }

        .actions {
          display: flex;
          margin-left: auto;
          cursor: pointer;

          .default {
            display: flex;
            align-items: center;
          }  
        }
      }
    }

    .payment-button {
      cursor: pointer;
      border-radius: 5px;
      border: 0;
      background: #002857;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-shadow: none;
      padding: 10px 0;
    }
  }
}

.ant-dropdown-menu {
  .action-item {
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }  
}

@media (max-width: 768px) {
  .payment-modal {  
    .payment-method-container {
      max-width: calc(100% - 20px);
      width: calc(100% - 20px);
      top:48%;
      .add-payment-method, .update-payment-method {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}