.segmentBio {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  .bioUpperSection {
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    .css-137p82i-MuiButtonBase-root-MuiButton-root {
      padding: 0px;
    }
  }
 
  .upperSectionMobile {
    background-color: white !important;
    color: white;
    margin-bottom: 0px;
  }
  .avatarBio {
    margin-left: -10%;
  }
  [class~="ant-carousel"] {
    [class~="slick-slider"] {
      li button {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        color: 002857 !important;
        background-color: white;
        border: 2px solid #002857 !important;
        border-color: #002857 !important;
      }
      li.slick-active button {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        color: #002857 !important;
        // background-color: 002857 !important;
      }
      .slick-dots li button:before {
        background-color: #002857 !important;
      }
      .slick-dots li.slick-active button:before {
        background-color: #000 !important;
      }
    }
  }

  .badge {
    border-radius: 4px;
    margin: 4px;
    font-size: 16px;
    font-weight: 500;
  }
  .segmentBioGreenBadge {
    background-color: rgba(125, 198, 153, 0.3);
    color: #7dc699;
  }
  .segmentBioBlueBadge {
    background-color: rgba(2, 175, 239, 0.3);
    color: #02afef;
  }
  .segmentBioYellowBadge {
    background-color: rgba(254, 164, 46, 0.25);
    color: #fea42e;
  }
  .bioLowerSection {
    padding: 20px;
  }
  .bioLowerSectionMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 0px;
    margin-top: -10px;
  }
  .bioChipsMobile {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .skeletonAvatar {
    height: 70px;
    width: 70px;
  }
  .skeletonHeading {
    padding: 5px;
    width: 100px;
    border-radius: 15px;
  }
  .skeletonSubHeading {
    padding: 4px;
    width: 60px;
    border-radius: 15px;
  }
  .skeletonBadge {
    margin: 0px !important;
    padding: 16px;
    width: 90px;
    border-radius: 5px;
  }
  .skeletonText {
    width: 60%;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1360px) and (min-width: 1200px) {
  .segmentBio {
    .avatarBio {
      margin-left: 20px !important;
    }
  }
}
@media only screen and (min-width: 600px) {
  .customer-insights .ant-carousel .slick-track {
    overflow: scroll;
  }
}
.mobile [class~="slick-slider"] {
  padding-bottom: 20px;
}

.slick-track {
  margin-left: inherit;
  padding-bottom: 10px !important;
}
.ratingBox {
  display: inline-flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
  // font-size: 3rem;
  padding: 0px;
  p {
    margin-left: 6px;
    line-height: 18px;
    //font-size: 3rem;
  }
  svg {
    height: 12px;
    width: 12px;
    padding-left: 6px;
    padding-top: 1px;
  }
}

.viewReviews {
  color: #0050bc;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.listReviews {
  width: 73%;
  margin-left: 15%;
  margin-top: 10%;
  margin-bottom: 7%;
  .reviewItem {
    padding: 10px 14px 17px 21px;
    box-sizing: border-box;
    border-radius: 5px;
    background: rgba(2, 175, 239, 0.05);
    margin-bottom: 29px;

    .itemHead {
      display: flex;
      align-items: end;
      margin-bottom: 17px;

      .ant-rate {
        font-size: 16px;
        margin: 0;
      }
      .ant-rate-star {
        margin-right: 4px; 
      }
      
      h5 {
        color: #707070;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 0 10px;
      }
      p {
        color: #0050bc;
        text-align: right;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0 0 0 auto;
      }
    }
    .comment {
      margin: 0 0 6px;
      color: #6f7174;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    .readMore {
      color: #0050bc;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .viewMoreAction {
    text-align: right;
    margin-bottom: 60px;

    a {
      color: #0050bc;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

  }
}

.customer-insights .ant-carousel .slick-track {
  gap: 0px !important;
}

.slick-dots li button {
  width: 100px; /* Increase dot width */
  height: 100px; /* Increase dot height */
  border-radius: 50%; /* Make the dots round */
  background-color: white !important; /* Adjust color if needed */
}

.slick-dots li.slick-active button {
  background-color: rgba(0, 0, 0, 0.9); /* Change color for active dot */
}

.customer-insights {
  .ant-carousel {
    .slick-dots {
      bottom: -20px !important ;
      li {
        width: 12px !important;
        height: 12px !important;
        button {
          height: 100% !important;
          border-radius: 50%;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .customer-insights .ant-carousel .slick-track > * {
    margin-right: 13px ;
  }
  // .segmentBio {
  //   margin-left: 15px;
  // }
}
.custom-rate .ant-rate-star-full {
  color: #52c41a; /* Active color */
}

.custom-rate .ant-rate-star-zero {
  color: #52c41a; /* Inactive color */
}
.reviewContainer {
  max-height: 400px; 
  overflow-y: auto;
  overflow-x: hidden; 
  padding-right: 8px; 
  padding-bottom: 10px;
  margin-right: 7px;
}

.reviewContainer::-webkit-scrollbar {
  width: 6px; 
}

.reviewContainer::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 3px;
}

.reviewContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar hover color */
}

.reviewContainer::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

.viewAll {
  position: absolute;
  color: #0050bc;
  bottom: 0;
  right: 0;
  padding: 10px;
  margin: 10px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

#ratingDialog {
  overflow: hidden;
  font-family: 'Gotham' !important;
}
.rateDialogue {
  width: 600px;
  height: auto;
  margin: 0 auto; 
}

@media screen and (max-width: 768px) {
  .rateDialogue {
    width: 370px;
    height: 660px;
    margin-top: 15%;
  }
}

@media screen and (max-width: 375px) {
  .rateDialogue {
    width: 280px; 
    height: 600px; 
    margin-top: 10%;
  }
}
@media screen and (min-width: 376px) and (max-width: 390px) {
  .rateDialogue {
    width: 330px; 
    height: 600px; 
    margin-top: 10%;
  }
}