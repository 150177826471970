.container {
  // Main container styles here
  
  &.blur {
    filter: blur(8px);  // Apply blur effect to the background
    transition: filter 0.3s ease-in-out;
  }
}


// Optional: Add styles to ensure only the background is blurred, not the Popover itself
.popupPicker {
  z-index: 999;
}
// Popup picker styling
.popupPicker {
  // Ant Design popover content overrides
  .ant-popover-content {
    // Popover inner content padding and margin
    .ant-popover-inner {
      padding: 6px;
      margin-top: 5px;
      margin-right: 35px;
    }

    // Hides the date display wrapper
    .rdrDateDisplayWrapper {
      display: none;
    }

    // Adjusts the padding for the month and year section
    .rdrMonthAndYearWrapper {
      padding-top: 0;
    }
    // Adjusts selected date ranges and edges styling
    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      top: 2px;
      bottom: 2px;
    }

    // Styles for the day number inside the calendar
    .rdrDayNumber {
      span {
        color: #151515; // Text color for day numbers
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }

    // Styles for passive (disabled) days
    .rdrDayPassive {
      .rdrDayNumber {
        span {
          opacity: 0.2; // Lower opacity for passive days
        }
      }
    }

    // In-range day color adjustments
    .rdrInRange {
      color: #e6f1fe !important; // Lighter color for selected range
    }

    // Ensure day numbers are visible within range
    .rdrDayNumber {
      span {
        color: #151515 !important;
      }
    }

    // Customize start and end edge colors of the range
    .rdrStartEdge,
    .rdrEndEdge {
      color: #02afef !important; // Blue color for start and end of range
    }

    // Styles for the current day
    .rdrDayToday {
      .rdrStartEdge {
        // Override if needed
      }

      .rdrDayNumber {
        span {
          &:after {
            background: #02afef !important; // Blue highlight for today's date
          }
        }
      }
    }

    // Disabled days styling
    .rdrDayDisabled {
      .rdrDayNumber {
        opacity: 0.5; // Lower opacity for disabled days

        span {
          // Add custom color if necessary
        }
      }
    }

    // Styling for actions (Submit and Reset buttons)
    .actions {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 20px; // Space between buttons
      padding-top: 16px;
      border-top: 1px solid #eff2f7; // Top border separating buttons

      // Reset button styles (empty as of now)
      .btnReset {}

      // Submit button styling with color adjustments
      .btnSubmit {
        background: #002857 !important;
        border-color: #002857 !important;
      }
    }
  }
}

// Button styling for calendar trigger
#btnCalendar {
  min-width:-webkit-fill-available ;
border: none;
  background: #fff;
  // padding: 8px 0px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 5px; // Rounded button corners
  // margin-right: -5px;
  margin-bottom: 3%;
  margin-left: 1%;
  // Date text styling inside button
  .dates {
    color: #002857;
    font-size: 15px;
    font-weight: 300;
    line-height: 100%;
  }

  // Icon spacing adjustment
  .iconCalendar {
    margin-left: 6px;
  }
}

// Media query for responsiveness on smaller screens
@media (max-width: 991px) {
  .popupPicker {
    .popupPickerContent {
      .rdrDefinedRangesWrapper {
        display: none; // Hide predefined range options on small screens
      }
    }
  }
}
.ant-popover-arrow {
  display: none !important;
}
#overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* This ensures 60% opacity for black background */
  z-index: 998;
  // display: none; /* Hidden by default */
}

#overlay.active {
  display: block; /* Show overlay when active */
}

.popupPicker {
  z-index: 1000; /* Ensure the popover is above the overlay and other content */
}



#feed {
  // position: relative;
  z-index: 999; /* Ensure feed content stays above any other background content */
}

.feed-overlay {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // color:grey;
  background-color: rgba(0, 0, 0, 0.4) !important; /* 60% opacity */
  z-index: 1000; /* Below the Popover and feed content */
}


.feed-overlay.active {
  display: block; /* Show overlay when active */
}
.mod-overlay {
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // color: #424242 !important;
   filter: brightness(0.5);
}
.paper-overlay{
  background-color: rgba(0, 0, 0, 0.1) !important;
  // color: #424242 !important;
}
@media (max-width: 339px) {
  .rdrMonth {
    width: 96% !important;
  }
}
