@import "./assets/fonts/font/stylesheet.css";

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("assets/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"), url("assets/fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Thin";
  src: local("Lato-Thin"), url("assets/fonts/Lato-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Light";
  src: local("Lato-Light"),
    url("assets/fonts/Lato-Light.ttf") format("truetype");
}

body {
  margin: 0;
  background-color: #eaebec;
  /* font-family: "Lato-Bold"; */
  /* font-family: 'Gotham'; */
  /* font-family: "Gotham" !important; */
  font-family: "Gotham", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Roboto', sans-serif; */
}

button,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  /* font-family: "Lato-Bold"; */
  /* font-family: 'Gotham'; */
  font-family: "Gotham", sans-serif;
  margin: 0;
}
input[type="text"] {
  /* font-family: "Lato-Bold"; */
  /* font-family: 'Gotham'; */
  font-family: "Gotham", sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
  /* monospace; */
  /* font-family: 'Gotham'; */
  font-family: "Gotham", sans-serif;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: none;
  // border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #999;
  // background-color: #002857;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.w-full {
  width: 100% !important;
}

.w-half {
  width: 50%;
}